var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-term" },
        [
          _c(
            "el-form",
            { staticClass: "demo-form-inline", attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-plus" },
                      on: { click: _vm.addConfigInfo },
                    },
                    [_vm._v("创建配置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "", stripe: "" } },
        [
          _c("el-table-column", {
            attrs: {
              label: "项目名",
              prop: "projectName",
              "min-width": "60",
              fixed: "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "环境", prop: "env", "min-width": "60" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "集群", prop: "cluster", "min-width": "60" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "描述", prop: "description", "min-width": "100" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作者", prop: "sourceUser", "min-width": "30" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "更新时间", "min-width": "43" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      _vm._s(_vm._f("timestampFormat")(scope.row.updatedTime))
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "创建时间", "min-width": "43" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      _vm._s(_vm._f("timestampFormat")(scope.row.createdTime))
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { fixed: "right", label: "操作", width: "210" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "primary",
                          icon: "el-icon-view",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.toConfigInfo(scope.row)
                          },
                        },
                      },
                      [_vm._v("配置详情\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "primary",
                          icon: "el-icon-view",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.toHistoryConfigInfos(scope.row)
                          },
                        },
                      },
                      [_vm._v("历史配置\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }